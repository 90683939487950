body{
    padding: 0;
    margin: auto;
    background:#B0C4DE;
    color: rgb(0, 0, 0);
}

*{
    font-family: 'alex_brushregular', Arial, "sans-serif";
}

.MainPage{
    display: grid;
    grid-template-columns: 50% 50%;
}

.tlo{
    width: 100%;
    margin: 10px;
    display: grid;
    grid-template-columns: auto auto;
}

.tlo img{
    width: 100%;
    margin: 0;
}

.nazwa-szkoly{
    margin: auto;
    text-align: center;
}

h3{
    color: rgb(1, 1, 170);
    text-shadow: 1px 1px 3px rgb(0, 0, 0);
    font-size: 80px;
    font-style: italic;
    width: 80%;
    margin: 20px;
    margin-right: 90px;
}

hr{
    width: 30%;
    border-top: 1px solid black;
    grid-column-start: span 2;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

h4 {
    font-size: 20px;
}

.smallPage{
    position: fixed;
    background-color: #fdfdfd;
    border-radius: 6px;
    border: 2px solid #B0C4DE;
    box-shadow: 0 0 9em black, 0 0 100px black;
    width: 60%;
    height: 90%;
    top: 5%;
    left: 20%;
    z-index: 2;
    overflow-y: scroll;
}

.smallPage h2{
    margin: 50px;
}

.smallPage p{
    text-align: center;
    margin: auto;
}

.smallPage p, ul, img {
    margin: 50px;
}

.smallPage img{
    text-align: center;
    margin: auto;
}

::-webkit-scrollbar-track
{
  border-radius: 10px;
  background-color: rgb(100, 100, 100);
}

::-webkit-scrollbar
{
  width: 12px;
  border-radius: 10px;
  background-color: rgb(100, 100, 100);
  
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: rgb(153, 153, 153);
  
}

.close-container{
    position: absolute;
    right: 6%;
    top: 2%;
    cursor: pointer;
}

.leftright{
    height: 4px;
    width: 20px;
    position: absolute;
    margin-top: 24px;
    background-color: rgb(60, 161, 255);
    border-radius: 2px;
    transform: rotate(45deg);
}

.rightleft{
    height: 4px;
    width: 20px;
    position: absolute;
    margin-top: 24px;
    background-color: rgb(60, 161, 255);
    border-radius: 2px;
    transform: rotate(-45deg);
}



.cloud{
    text-align: center;
    width:250px;
    height:100px;
    background:rgb(0, 0, 255);
    box-shadow: 20px 20px rgba(0,0,0,0.2);
    border-radius:200px;
    margin: auto;
    cursor: pointer;
}

.cloud::after, .cloud::before{
    content:"";
    position:relative;
    display:inline-block;
    background:inherit;
    border-radius:inherit;
    cursor: pointer;
    z-index: 1;
}

.cloud::after{
    width:80px;
    height:80px;
    top:-210px;
    left:-35px;
}

.cloud::before{
    width:100px;
    height:100px;
    top: -50px;
    left: 40px;
}

.all-cloud{
    display: grid;
    grid-template-columns: repeat(4, 25%);
    margin: auto;
    margin-top: 100px;
}

h2{
    text-align: center;
    margin: auto;
    font-size: 50px;
}

.back_next input{
    height: 45px;
    width: 45px;
    margin: 10px;
    font-weight: 900;
    border-radius: 5px;
    border: 1px solid rgb(60, 161, 255);
    background-color:rgb(60, 161, 255);
    color: white;
    outline: none
}

.back_next input:hover{
    background-color: rgb(0, 132, 255);
    border: 1px solid rgb(0, 132, 255);

}

.back{
    position: fixed;
    top: 50%;
    left: 21%;
}

.next{
    position: fixed;
    top: 50%;
    right: 21%;
}

@media 
 (max-device-width: 800px) {
    .MainPage{
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
    }
    .nazwa-szkoly{
        grid-row: 2;
    }
    .all-cloud{
        display: grid;
        grid-template-columns: 100%;
    }
    .cloud{
        margin: auto;
        margin-bottom: 150px;
        width:200px;
        height:80px;
        box-shadow: 10px 10px rgba(0,0,0,0.2);
    }        
    .cloud::after{
        width:50px;
        height:50px;
        top:-195px;
        left:-25px;
    }
    
    .cloud::before{
        width:80px;
        height:80px;
        top: -40px;
        left: 30px;
    }
    .smallPage{
        width: 90%;
        height: 90%;
        top: 5%;
        left: 5%;
        z-index: 2;
        overflow-y: scroll;
    }
    .smallPage img{
        width: 90%;
    }
    .smallPage h2{
        font-size: 25px;
    }
    .back{
        left: 6%;
    }
    .next{
        right: 6%;
    }

    h3{
        margin: auto;
        font-size: 40px;
        margin-top: 30px;
    }

    .tlo{
        margin: 0;
        width: 100%;
    }

  }

  @media 
 (min-device-width: 800px) and (max-width: 1200px) {
    .MainPage{
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
    }
    .nazwa-szkoly{
        grid-row: 2;
    }
    .all-cloud{
        display: grid;
        grid-template-columns: 100%;
    }
    .cloud{
        margin: auto;
        margin-top: 100px;
        margin-bottom: 150px;
    }
    .smallPage{
        width: 90%;
        height: 90%;
        top: 5%;
        left: 5%;
        z-index: 2;
        overflow-y: scroll;
    }

    .back{
        left: 6%;
    }
    .next{
        right: 6%;
    }

    h3{
        margin: auto;
        margin-top: 30px;
    }

    .tlo{
        margin: 0;
    }

  }
